import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, Link } from "react-router-dom";
import Lottie from "react-lottie";
import { fetchTeamLogo } from "../services/api";

const formatMarketValue = (value) => {
  if (!value || value === "N/A") return "-";
  
  const million = 1000000;
  const thousand = 1000;
  
  if (value >= million) {
    return `${(value / million).toFixed(1)}M €`;
  } else if (value >= thousand) {
    return `${(value / thousand).toFixed(0)}K €`;
  }
  
  return `${value} €`;
};

const Transfers = () => {
  const { teamId } = useParams();
  const [transfers, setTransfers] = useState({
    transfersIn: [],
    transfersOut: [],
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [animationData, setAnimationData] = useState(null);
  const [teamLogo, setTeamLogo] = useState(null);
  const [teamLogos, setTeamLogos] = useState({});

  const API_KEY = "a5d1cfe5abmsh08225bdf4e607e9p16b9a6jsn263facf47023";
  const API_HOST = "footapi7.p.rapidapi.com";

  useEffect(() => {
    const fetchAnimationData = async () => {
      try {
        const response = await fetch("/load.json");
        const data = await response.json();
        setAnimationData(data);
      } catch (error) {
        console.error("Animasyon yüklenirken hata oluştu:", error);
      }
    };

    fetchAnimationData();
  }, []);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const fetchTransferData = async () => {
    try {
      const options = {
        method: "GET",
        url: `https://footapi7.p.rapidapi.com/api/team/${teamId}/transfers`,
        headers: {
          "x-rapidapi-key": API_KEY,
          "x-rapidapi-host": API_HOST,
        },
      };

      const response = await axios.request(options);
      const transfersData = {
        transfersIn: response.data.transfersIn || [],
        transfersOut: response.data.transfersOut || [],
      };
      setTransfers(transfersData);
    } catch (error) {
      if (error.response && error.response.status === 429) {
        setError("Çok fazla istek yapıldı! Lütfen daha sonra tekrar deneyin.");
      } else {
        setError(`Transfer verileri getirilirken bir hata oluştu: ${error.message}`);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (teamId) {
      fetchTransferData();
    }
  }, [teamId]);

  const downloadLogo = async (teamId) => {
    try {
      await axios.post('/api/download-logos.php', {
        teamIds: [teamId],
        tournamentIds: []
      });
    } catch (error) {
      console.error('Logo indirme hatası:', error);
    }
  };

  const renderTeamCell = (team) => (
    <td className="py-3 px-4">
      <div className="flex items-center gap-3">
        {team?.id && (
          <img
            src={`/logos/team/${team.id}.png`}
            alt={`${team.name} logo`}
            className="w-10 h-10 object-contain"
            onError={async (e) => {
              if (!e.target.src.includes('placeholder')) {
                await downloadLogo(team.id);
                e.target.src = 'https://via.placeholder.com/40x40?text=' + team.name?.charAt(0);
              }
            }}
          />
        )}
        <span className="text-white">{team?.name || "-"}</span>
      </div>
    </td>
  );

  if (loading) {
    return (
      <div className="container mx-auto flex items-center justify-center min-h-[400px] bg-white">
        {animationData ? (
          <Lottie options={defaultOptions} height={150} width={150} />
        ) : (
          <div>Yükleniyor...</div>
        )}
      </div>
    );
  }

  if (error) {
    return (
      <div className="container mx-auto flex items-center justify-center min-h-[400px]">
        <div className="text-red-500 text-center p-4">{error}</div>
      </div>
    );
  }

  return (
    <div className="w-full px-4 mt-3 pt-3 rounded-md bg-black">
      <div className="flex flex-col md:flex-row justify-between gap-8">
        <div className="w-full">
          <h4 className="text-md font-bold text-white mb-4">Gelenler</h4>
          <div className="overflow-x-auto">
            <table className="w-full rounded-md">
              <thead>
                <tr>
                  <th className="text-left text-white py-3 px-4">Oyuncu</th>
                  <th className="text-left text-white py-3 px-4">Geldiği Takım</th>
                  <th className="text-right text-white py-3 px-4">Bedel</th>
                </tr>
              </thead>
              <tbody>
                {transfers.transfersIn.map((transfer, index) => (
                  <tr
                    key={`in-${index}-${transfer.player.name}`}
                    className={`border-b border-black-800 ${
                      index % 2 === 0 ? "bg-[#0d0d0d]" : "bg-[#171717]"
                    }`}
                  >
                    <td className="py-3 px-4">
                      <Link 
                        to={`/player/${transfer.player.id}`}
                        className="hover:text-blue-400 transition-colors"
                      >
                        <div className="text-white">{transfer.player.name}</div>
                        <div className="text-sm text-gray-400">
                          {transfer.player.position === 'D' ? 'Defans' :
                           transfer.player.position === 'M' ? 'Orta Saha' :
                           transfer.player.position === 'F' ? 'Forvet' :
                           transfer.player.position === 'GK' ? 'Kaleci' :
                           transfer.player.position}
                        </div>
                      </Link>
                    </td>
                    {renderTeamCell(transfer.transferFrom)}
                    <td className="py-3 px-4 text-right text-white">
                      {formatMarketValue(transfer.transferFee)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        <div className="w-full">
          <h4 className="text-md font-bold text-white mb-4">Gidenler</h4>
          <div className="overflow-x-auto">
            <table className="w-full">
              <thead>
                <tr>
                  <th className="text-left text-white py-3 px-4">Oyuncu</th>
                  <th className="text-left text-white py-3 px-4">Gittiği Takım</th>
                  <th className="text-right text-white py-3 px-4">Bedel</th>
                </tr>
              </thead>
              <tbody>
                {transfers.transfersOut.map((transfer, index) => (
                  <tr
                    key={`out-${index}-${transfer.player.name}`}
                    className={`border-b border-black-800 ${
                      index % 2 === 0 ? "bg-[#0d0d0d]" : "bg-[#171717]"
                    }`}
                  >
                    <td className="py-3 px-4">
                      <Link 
                        to={`/player/${transfer.player.id}`}
                        className="hover:text-blue-400 transition-colors"
                      >
                        <div className="text-white">{transfer.player.name}</div>
                        <div className="text-sm text-gray-400"> {transfer.player.position === 'D' ? 'Defans' :
                           transfer.player.position === 'M' ? 'Orta Saha' :
                           transfer.player.position === 'F' ? 'Forvet' :
                           transfer.player.position === 'GK' ? 'Kaleci' :
                           transfer.player.position}</div>
                      </Link>
                    </td>
                    {renderTeamCell(transfer.transferTo)}
                    <td className="py-3 px-4 text-right text-white">
                      {formatMarketValue(transfer.transferFee)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Transfers;
