import React, { useEffect, useState } from "react";
import axios from "axios";
import Lottie from "react-lottie";

const LiveScores = () => {
  const [tournaments, setTournaments] = useState([]);
  const [selectedTournament, setSelectedTournament] = useState(null);
  const [seasons, setSeasons] = useState([]);
  const [selectedSeason, setSelectedSeason] = useState(null);
  const [standings, setStandings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [animationData, setAnimationData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchAnimationData = async () => {
      try {
        const response = await fetch("/load.json");
        const data = await response.json();
        setAnimationData(data);
      } catch (error) {
        console.error("Animasyon yüklenirken hata oluştu:", error);
      }
    };

    fetchAnimationData();
  }, []);

  // Ligleri ve sezonları getir
  useEffect(() => {
    const fetchTournaments = async () => {
      try {
        const options = {
          method: 'GET',
          url: 'https://footapi7.p.rapidapi.com/api/team/3050/standings/seasons',
          headers: {
            'x-rapidapi-key': 'a5d1cfe5abmsh08225bdf4e607e9p16b9a6jsn263facf47023',
            'x-rapidapi-host': 'footapi7.p.rapidapi.com'
          }
        };

        const response = await axios.request(options);
        const tournamentSeasons = response.data.tournamentSeasons;

        // Sadece grup olmayan turnuvaları filtrele
        const filteredTournaments = tournamentSeasons.filter(ts => !ts.tournament.isGroup);
        setTournaments(filteredTournaments);

        if (filteredTournaments.length > 0) {
          const firstTournament = filteredTournaments[0];
          setSelectedTournament(firstTournament);
          setSeasons(firstTournament.seasons);
          setSelectedSeason(firstTournament.seasons[0]);
        }
      } catch (error) {
        console.error("Turnuvalar yüklenirken hata:", error);
        setError("Turnuvalar yüklenirken hata oluştu");
      }
    };

    fetchTournaments();
  }, []);

  // Seçili lig ve sezona göre puan durumunu getir
  useEffect(() => {
    const fetchStandings = async () => {
      if (!selectedTournament?.tournament?.uniqueTournament?.id || !selectedSeason?.id) return;

      setLoading(true);
      try {
        const options = {
          method: 'GET',
          url: `https://footapi7.p.rapidapi.com/api/tournament/${selectedTournament.tournament.uniqueTournament.id}/season/${selectedSeason.id}/standings/total`,
          headers: {
            'x-rapidapi-key': 'a5d1cfe5abmsh08225bdf4e607e9p16b9a6jsn263facf47023',
            'x-rapidapi-host': 'footapi7.p.rapidapi.com'
          }
        };

        const response = await axios.request(options);
        if (response.data.standings?.[0]?.rows) {
          setStandings(response.data.standings[0].rows);
        }
      } catch (error) {
        console.error("Puan durumu yüklenirken hata:", error);
        setError("Puan durumu yüklenirken hata oluştu");
      } finally {
        setLoading(false);
      }
    };

    fetchStandings();
  }, [selectedTournament, selectedSeason]);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          backgroundColor: "black",
        }}
      >
        {animationData && (
          <Lottie options={defaultOptions} height={150} width={150} />
        )}
        {!animationData && <div className="text-white">Yükleniyor...</div>}
      </div>
    );
  }

  if (error) {
    return <div className="text-red-500">{error}</div>;
  }

  const getPromotionColor = (promotionId) => {
    switch (promotionId) {
      case 3:
        return "bg-[#ff003c]"; // Küme düşme
      case 11:
        return "bg-[#018941]"; // Yükselme
      case 26:
        return "bg-[#05e65f]"; // yükselme playoff
      case 13:
        return "bg-red-500"; //yükselme
      case 804:
        return "bg-[#170095]"; // Şampiyonlar Ligi
      case 968:
        return "bg-orange-300"; // Avrupa Ligi Eleme
      case 503:
        return "bg-[#00ff7b]"; // Konferans Ligi Eleme
      case 808:
        return "bg-[#ff1e00]"; // Avrupa Ligi
      case 6:
        return "bg-purple-500"; // Playoff
      case 14:
        return "bg-[#f97001]"; // Qualification Playoffs
      case 24:
        return "bg-[#113cc6]"; // Champions League Qualification
      default:
        return "bg-[#0d0d0d]"; // Siyah arka plan
    }
  };

  const handleTournamentChange = (tournamentId) => {
    const tournament = tournaments.find(t => t.tournament.uniqueTournament.id === parseInt(tournamentId));
    setSelectedTournament(tournament);
    setSeasons(tournament.seasons);
    setSelectedSeason(tournament.seasons[0]);
  };

  return (
    <div className="live-scores-container rounded-lg mt-4 bg-black p-4">
      {/* Lig ve Sezon Seçimi */}
      <div className="flex gap-4 mb-6">
        <select
          value={selectedTournament?.tournament?.uniqueTournament?.id || ""}
          onChange={(e) => handleTournamentChange(e.target.value)}
          className="bg-[#1d1d1d] text-white px-4 py-2 rounded-md border border-gray-700"
        >
          {tournaments.map((t) => (
            <option key={t.tournament.uniqueTournament.id} value={t.tournament.uniqueTournament.id}>
              {t.tournament.name}
            </option>
          ))}
        </select>

        <select
          value={selectedSeason?.id || ""}
          onChange={(e) => setSelectedSeason(seasons.find(s => s.id === parseInt(e.target.value)))}
          className="bg-[#1d1d1d] text-white px-4 py-2 rounded-md border border-gray-700"
        >
          {seasons.map((season) => (
            <option key={season.id} value={season.id}>
              {season.name}
            </option>
          ))}
        </select>
      </div>

      <table className="table-auto w-full text-white rounded-lg rounded-full">
        <thead>
          <tr className="bg-[#0d0d0d] rounded-lg">
            <th className="px-4 py-2">Sıra</th>
            <th className="px-4 py-2 text-start">Takım</th>
            <th className="px-4 py-2">O</th>
            <th className="px-4 py-2">G</th>
            <th className="px-4 py-2">B</th>
            <th className="px-4 py-2">M</th>
            <th className="px-4 py-2">A</th>
            <th className="px-4 py-2">Y</th>
            <th className="px-4 py-2">AV</th>
            <th className="px-4 py-2">P</th>
          </tr>
        </thead>
        <tbody>
          {standings.map((standing) => {
            const {
              team,
              matches,
              wins,
              draws,
              losses,
              scoresFor,
              scoresAgainst,
              points,
              promotion,
            } = standing;

            const promotionColor = getPromotionColor(promotion?.id);

            return (
              <tr 
                key={team.id}
                className={`${promotionColor} bg-opacity-10 rounded-lg`}
              >
                <td className="text-center w-[20px]">
                  <span
                    className={`inline-block px-2 py-1 text-white text-xs font-semibold rounded-full ${promotionColor}`}
                  >
                    {standing.position}
                  </span>
                </td>
                <td className="py-2 flex items-center text-start">
                  <img 
                    src={`/logos/team/${team.id}.png`}
                    alt={team.name}
                    className="w-6 h-6 mr-2 object-contain"
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = '/default-team-logo.png';
                    }}
                  />
                  {team.name}
                </td>
                <td className="px-4 py-2 text-center">{matches}</td>
                <td className="px-4 py-2 text-center">{wins}</td>
                <td className="px-4 py-2 text-center">{draws}</td>
                <td className="px-4 py-2 text-center">{losses}</td>
                <td className="px-4 py-2 text-center">{scoresFor}</td>
                <td className="px-4 py-2 text-center">{scoresAgainst}</td>
                <td className="px-4 py-2 text-center">
                  {scoresFor - scoresAgainst}
                </td>
                <td className="px-4 py-2 text-center font-bold">{points}</td>
              </tr>
            );
          })}
        </tbody>
      </table>

      {/* Açıklama Alanı */}
      <div className="space-y-2 p-3 bg-black mt-4">
        {standings.some(team => team.promotion?.id === 804) && (
          <div className="flex items-center gap-2">
            <div className="w-4 h-4 rounded-full bg-[#170095]"></div>
            <span className="text-white">Şampiyonlar Ligi</span>
          </div>
        )}
        {standings.some(team => team.promotion?.id === 24) && (
          <div className="flex items-center gap-2">
            <div className="w-4 h-4 rounded-full bg-[#113cc6]"></div>
            <span className="text-white">Şampiyonlar Ligi Elemesi</span>
          </div>
        )}
        {standings.some(team => team.promotion?.id === 808) && (
          <div className="flex items-center gap-2">
            <div className="w-4 h-4 rounded-full bg-[#ff1e00]"></div>
            <span className="text-white">Avrupa Ligi</span>
          </div>
        )}
        {standings.some(team => team.promotion?.id === 968) && (
          <div className="flex items-center gap-2">
            <div className="w-4 h-4 rounded-full bg-orange-300"></div>
            <span className="text-white">Avrupa Ligi Eleme</span>
          </div>
        )}
        {standings.some(team => team.promotion?.id === 503) && (
          <div className="flex items-center gap-2">
            <div className="w-4 h-4 rounded-full bg-[#00ff7b]"></div>
            <span className="text-white">Konferans Ligi Eleme</span>
          </div>
        )}
        {standings.some(team => team.promotion?.id === 6) && (
          <div className="flex items-center gap-2">
            <div className="w-4 h-4 rounded-full bg-purple-500"></div>
            <span className="text-white">Playoff</span>
          </div>
        )}
        {standings.some(team => team.promotion?.id === 14) && (
          <div className="flex items-center gap-2">
            <div className="w-4 h-4 rounded-full bg-[#f97001]"></div>
            <span className="text-white">Eleme Playoff</span>
          </div>
        )}
        {standings.some(team => team.promotion?.id === 3) && (
          <div className="flex items-center gap-2">
            <div className="w-4 h-4 rounded-full bg-[#ff003c]"></div>
            <span className="text-white">Küme Düşme</span>
          </div>
        )}
        {standings.some(team => team.promotion?.id === 11) && (
          <div className="flex items-center gap-2">
            <div className="w-4 h-4 rounded-full bg-[#018941]"></div>
            <span className="text-white">Yükselme</span>
          </div>
        )}
        {standings.some(team => team.promotion?.id === 26) && (
          <div className="flex items-center gap-2">
            <div className="w-4 h-4 rounded-full bg-[#05e65f]"></div>
            <span className="text-white">Yükselme Playoff</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default LiveScores;
