import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { config } from '../config';

const LiveMatchRow = ({ match }) => {
  const navigate = useNavigate();
  const [matchTime, setMatchTime] = useState('');
  const [homeTeamLogo, setHomeTeamLogo] = useState(null);
  const [awayTeamLogo, setAwayTeamLogo] = useState(null);
  const [tournamentLogo, setTournamentLogo] = useState(null);

  useEffect(() => {
    const loadLogos = () => {
      try {
        if (match.homeTeam?.id) {
          setHomeTeamLogo(`/logos/team/${match.homeTeam.id}.png`);
        }
        if (match.awayTeam?.id) {
          setAwayTeamLogo(`/logos/team/${match.awayTeam.id}.png`);
        }
        if (match.tournament?.uniqueTournament?.id) {
          setTournamentLogo(`/logos/tournament/${match.tournament.uniqueTournament.id}.png`);
        }
      } catch (error) {
        console.error("Error loading logos:", error);
      }
    };

    loadLogos();
  }, [match]);

  useEffect(() => {
    const calculateMatchTime = () => {
      const now = Math.floor(Date.now() / 1000);
      const matchStart = match.startTimestamp;
      const timeDiff = now - matchStart;
      const minutes = Math.floor(timeDiff / 60);

      if (minutes < 0) return 'Başlamadı';
      if (minutes <= 45) return `${minutes}'`;
      if (minutes > 45 && minutes < 60) return 'Devre Arası';
      if (minutes >= 60 && minutes <= 105) return `${minutes - 15}'`;
      if (match.status.description?.includes('Extra')) {
        const extraMinutes = minutes - 90;
        return `90+${extraMinutes}'`;
      }
      return match.status.description || 'Canlı';
    };

    const timer = setInterval(() => {
      setMatchTime(calculateMatchTime());
    }, 30000); // Her 30 saniyede bir güncelle

    setMatchTime(calculateMatchTime());
    return () => clearInterval(timer);
  }, [match]);

  return (
    <div 
      className="row p-1 gradient-bg mb-1 rounded-lg text-white flex items-center justify-between shadow-lg"
      onClick={() => navigate(`/match/${match.id}`)}
      style={{ cursor: 'pointer' }}
    >
      {/* Sol kısım - Turnuva ve Logo */}
      <div
        className="col-3 flex items-center justify-start bg-black p-2 rounded-lg"
        style={{ width: "160px" }}
      >
        {tournamentLogo && (
          <div className="bg-white p-1 rounded-lg mr-2 flex items-center justify-center">
            <img
              src={tournamentLogo}
              alt={`${match.tournament?.name} logo`}
              className="tournament-logo rounded-lg"
              style={{ 
                width: "30px", 
                height: "30px",
                objectFit: "contain"
              }}
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = '/default-tournament-logo.png';
              }}
            />
          </div>
        )}
        <div className="flex flex-col justify-center">
          <div
            className="tournament text-gray-300 font-bold"
            style={{ fontSize: "11px" }}
          >
            {match.tournament?.name?.replace("UEFA", "").trim()}
          </div>
          <div className="text-xs text-gray-400">
            {match.roundInfo?.name || `${match.roundInfo?.round}. Hafta`}
          </div>
        </div>
      </div>

      {/* Orta kısım - Takımlar ve Skor */}
      <div className="col-6 flex items-center justify-center">
        <div className="flex items-center">
          <div
            className="team-name"
            style={{ width: "165px", textAlign: "right", fontWeight: "bold" }}
          >
            {match.homeTeam.name}
          </div>
          {homeTeamLogo && (
            <img
              src={homeTeamLogo}
              alt={`${match.homeTeam.name} logo`}
              className="team-logo mx-2"
              style={{ width: "35px", height: "35px" }}
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = '/default-team-logo.png';
              }}
            />
          )}
        </div>

        {/* Skor Kısmı */}
        <div
          className="score text-lg font-bold rounded-md"
          style={{
            background: "#ffffff",
            color: "#000000",
            padding: "6px 10px",
            borderRadius: "5px",
            minWidth: "80px",
            textAlign: "center",
            fontSize: "20px",
          }}
        >
          {match.homeScore.current} - {match.awayScore.current}
        </div>

        <div className="flex items-center">
          {awayTeamLogo && (
            <img
              src={awayTeamLogo}
              alt={`${match.awayTeam.name} logo`}
              className="team-logo mx-2"
              style={{ width: "35px", height: "35px" }}
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = '/default-team-logo.png';
              }}
            />
          )}
          <div
            className="team-name"
            style={{ width: "165px", textAlign: "left", fontWeight: "bold" }}
          >
            {match.awayTeam.name}
          </div>
        </div>
      </div>

      {/* Sağ kısım - Maç Durumu */}
      <div
        className="col-3 flex flex-col items-center justify-center bg-black p-2 rounded-lg"
        style={{ width: "135px" }}
      >
        <div className="flex flex-col items-center">
          <div className="text-red-500 font-bold animate-pulse">
            {match.status.type === 'inprogress' ? 'CANLI' : match.status.description}
          </div>
          <div className="text-sm text-gray-300 mt-1">
            {matchTime}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LiveMatchRow; 