import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { config } from '../config';

const MatchDetail = () => {
  const { matchId } = useParams();
  const navigate = useNavigate();
  const [matchDetails, setMatchDetails] = useState(null);
  const [incidents, setIncidents] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedTab, setSelectedTab] = useState('events');
  const [home, setHome] = useState(null);
  const [away, setAway] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [matchResponse, incidentsResponse, lineupResponse] = await Promise.all([
          axios.get(`${config.API_URL}/match/${matchId}`, {
            headers: {
              'X-RapidAPI-Key': config.API_KEY,
              'X-RapidAPI-Host': config.API_HOST,
            },
          }),
          axios.get(`${config.API_URL}/match/${matchId}/incidents`, {
            headers: {
              'X-RapidAPI-Key': config.API_KEY,
              'X-RapidAPI-Host': config.API_HOST,
            },
          }),
          axios.get(`${config.API_URL}/match/${matchId}/lineups`, {
            headers: {
              'X-RapidAPI-Key': config.API_KEY,
              'X-RapidAPI-Host': config.API_HOST,
            },
          }),
        ]);

        setMatchDetails(matchResponse.data.event);
        setIncidents(incidentsResponse.data.incidents);
        setHome(lineupResponse.data.home);
        setAway(lineupResponse.data.away);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching match data:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, [matchId]);

  const getMatchStatus = (status) => {
    switch (status.code) {
      case 0:
        return 'Başlamadı';
      case 1:
        return 'İlk Yarı';
      case 2:
        return 'Devre Arası';
      case 3:
        return 'İkinci Yarı';
      case 4:
        return 'Uzatma';
      case 5:
        return 'Penaltılar';
      case 6:
        return 'Maç Sonu';
      case 7:
        return 'İptal';
      case 8:
        return 'Tehir';
      case 9:
        return 'Tatil Edildi';
      case 10:
        return 'TBC';
      case 11:
        return 'Penaltılarla Bitti';
      case 12:
        return 'Uzatmada Bitti';
      default:
        return 'Bilinmiyor';
    }
  };

  const formatDate = (timestamp) => {
    const date = new Date(timestamp * 1000);
    return date.toLocaleDateString('tr-TR', {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  const renderIncidents = (incidents) => {
    let modifiedIncidents = [...incidents];

    // Olayları zamana göre sırala (küçükten büyüğe)
    modifiedIncidents.sort((a, b) => {
      const timeA = a.time + (a.addedTime || 0);
      const timeB = b.time + (b.addedTime || 0);
      return timeA - timeB;
    });

    // 45 ve 90. dakikaları ekle
    const periodsToAdd = [];
    incidents.forEach((incident) => {
      if (incident.incidentType === 'injuryTime') {
        const period = incident.time === 45 ? 45 : 90;
        periodsToAdd.push({
          time: period,
          incidentType: 'periodTime',
          text: `${period}. Dakika`,
          originalTime: period
        });
      }
    });

    // Periyot olaylarını ekle
    periodsToAdd.forEach(periodIncident => {
      const targetTime = periodIncident.originalTime;
      const insertIndex = modifiedIncidents.findIndex(inc => 
        (inc.time === targetTime && inc.incidentType === 'injuryTime') || 
        (inc.time > targetTime)
      );
      
      if (insertIndex !== -1) {
        modifiedIncidents.splice(insertIndex, 0, periodIncident);
      }
    });

    // Maç başlangıcı olayını en başa ekle
    modifiedIncidents.unshift({
      time: 0,
      incidentType: 'periodTime',
      text: 'Maç Başladı'
    });

    return modifiedIncidents.map((incident, index) => (
      <div key={index}>
        {renderIncident(incident)}
      </div>
    ));
  };

  const renderIncident = (incident) => {
    const isHome = incident.isHome;
    const time = incident.addedTime ? `${incident.time}+${incident.addedTime}` : incident.time;

    const renderContent = () => {
      switch (incident.incidentType) {
        case 'periodTime':
          return (
            <div className="w-full flex justify-center">
              <div className="bg-[#2a2a2a] rounded-lg py-1.5 px-4 w-[250px] text-center">
                <span className="text-gray-400 font-medium">{incident.text}</span>
              </div>
            </div>
          );

        case 'period':
          if (incident.text === 'HT') {
            return (
              <div className="w-full flex justify-center">
                <div className="bg-[#2a2a2a] rounded-lg py-1.5 px-4 w-[250px] text-center">
                  <span className="text-gray-400 font-medium">Devre Arası</span>
                </div>
              </div>
            );
          } else if (incident.text === 'FT') {
            return (
              <div className="w-full flex justify-center">
                <div className="bg-[#2a2a2a] rounded-lg py-1.5 px-4 w-[250px] text-center">
                  <span className="text-gray-400 font-medium">Maç Sonu: {incident.homeScore} - {incident.awayScore}</span>
                </div>
              </div>
            );
          }
          return null;

        case 'injuryTime':
          return (
            <div className="w-full flex justify-center">
              <div className="bg-[#2a2a2a] rounded-lg py-1.5 px-4 w-[250px] text-center">
                <span className="text-gray-400 font-medium">{incident.length}' Uzatma Verildi</span>
              </div>
            </div>
          );

        case 'varDecision':
          return (
            <div className="w-full flex justify-center">
              <div className="bg-[#2a2a2a] rounded-lg py-1.5 px-4 w-[250px] text-center flex items-center justify-center gap-2">
                <img src="/icons/var.png" alt="VAR" className="w-4 h-4" />
                <span className="text-gray-400 font-medium">
                  {incident.incidentClass === 'penaltyAwarded' ? 'Penaltı Kararı Verildi' : 
                   incident.incidentClass === 'penaltyNotAwarded' ? 'Penaltı Kararı İptal Edildi' : 
                   'VAR İncelemesi'}
                </span>
              </div>
            </div>
          );

        case 'goal':
          const isPenalty = incident.incidentClass === 'penalty';
          return (
            <>
              <div className={`flex items-center gap-1 ${isHome ? 'justify-end' : 'justify-start'} w-[45%] relative`}>
                {isHome && (
                  <>
                    <div className="absolute inset-0 bg-[#2a2a2a] rounded-lg" />
                    <div className="relative z-10 flex items-center gap-2 justify-end w-full px-3 py-2">
                      <div className="flex flex-col items-end">
                        <div className="flex items-center gap-2">
                          <span className="font-medium whitespace-nowrap">{incident.player?.name}</span>
                          {isPenalty && <img src="/icons/penalty.png" alt="Penaltı" className="w-4 h-4" />}
                        </div>
                        {incident.assist && (
                          <span className="text-xs text-gray-400">Asist: {incident.assist.name}</span>
                        )}
                      </div>
                      <img src="/icons/goal.png" alt="Gol" className="w-4 h-4" />
                    </div>
                  </>
                )}
              </div>
              <div className="w-[10%] flex justify-center items-center px-1">
                <span className="text-yellow-400 font-medium text-sm bg-[#2a2a2a] rounded-lg px-2 py-1">{time}'</span>
              </div>
              <div className={`flex items-center gap-1 justify-start w-[45%] relative`}>
                {!isHome && (
                  <>
                    <div className="absolute inset-0 bg-[#2a2a2a] rounded-lg" />
                    <div className="relative z-10 flex items-center gap-2 w-full px-3 py-2">
                      <img src="/icons/goal.png" alt="Gol" className="w-4 h-4" />
                      <div className="flex flex-col">
                        <div className="flex items-center gap-2">
                          {isPenalty && <img src="/icons/penalty.png" alt="Penaltı" className="w-4 h-4" />}
                          <span className="font-medium whitespace-nowrap">{incident.player?.name}</span>
                        </div>
                        {incident.assist && (
                          <span className="text-xs text-gray-400">Asist: {incident.assist.name}</span>
                        )}
                      </div>
                    </div>
                  </>
                )}
              </div>
            </>
          );

        case 'card':
          return (
            <>
              <div className={`flex items-center gap-1 ${isHome ? 'justify-end' : 'justify-start'} w-[45%] relative`}>
                {isHome && (
                  <>
                    <div className="absolute inset-0 bg-[#2a2a2a] rounded-lg" />
                    <div className="relative z-10 flex items-center gap-2 justify-end w-full px-3 py-2">
                      <span className="text-sm whitespace-nowrap">{incident.player?.name}</span>
                      <div className={`w-2 h-3 ${incident.incidentClass === 'yellow' ? 'bg-yellow-400' : 'bg-red-600'} rounded`}></div>
                    </div>
                  </>
                )}
              </div>
              <div className="w-[10%] flex justify-center items-center px-1">
                <span className="text-yellow-400 font-medium text-sm bg-[#2a2a2a] rounded-lg px-2 py-1">{time}'</span>
              </div>
              <div className={`flex items-center gap-1 justify-start w-[45%] relative`}>
                {!isHome && (
                  <>
                    <div className="absolute inset-0 bg-[#2a2a2a] rounded-lg" />
                    <div className="relative z-10 flex items-center gap-2 w-full px-3 py-2">
                      <div className={`w-2 h-3 ${incident.incidentClass === 'yellow' ? 'bg-yellow-400' : 'bg-red-600'} rounded`}></div>
                      <span className="text-sm whitespace-nowrap">{incident.player?.name}</span>
                    </div>
                  </>
                )}
              </div>
            </>
          );

        case 'substitution':
          return (
            <>
              <div className={`flex items-center gap-1 ${isHome ? 'justify-end' : 'justify-start'} w-[45%] relative`}>
                {isHome && (
                  <>
                    <div className="absolute inset-0 bg-[#2a2a2a] rounded-lg" />
                    <div className="relative z-10 flex flex-col items-end w-full px-3 py-1.5">
                      <div className="flex items-center gap-2">
                        <span className="text-sm whitespace-nowrap">{incident.playerIn?.name}</span>
                        <span className="text-green-500 text-xs">↑</span>
                      </div>
                      <div className="flex items-center gap-2">
                        <span className="text-sm text-gray-400 whitespace-nowrap">{incident.playerOut?.name}</span>
                        <span className="text-red-500 text-xs">↓</span>
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div className="w-[10%] flex justify-center items-center px-1">
                <span className="text-yellow-400 font-medium text-sm bg-[#2a2a2a] rounded-lg px-2 py-1">{time}'</span>
              </div>
              <div className={`flex items-center gap-1 justify-start w-[45%] relative`}>
                {!isHome && (
                  <>
                    <div className="absolute inset-0 bg-[#2a2a2a] rounded-lg" />
                    <div className="relative z-10 flex flex-col w-full px-3 py-1.5">
                      <div className="flex items-center gap-2">
                        <span className="text-green-500 text-xs">↑</span>
                        <span className="text-sm whitespace-nowrap">{incident.playerIn?.name}</span>
                      </div>
                      <div className="flex items-center gap-2">
                        <span className="text-red-500 text-xs">↓</span>
                        <span className="text-sm text-gray-400 whitespace-nowrap">{incident.playerOut?.name}</span>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </>
          );

        default:
          return null;
      }
    };

    // Özel olaylar için sınırlı genişlik kullan
    if (['periodTime', 'period', 'injuryTime', 'varDecision'].includes(incident.incidentType)) {
      return (
        <div className="flex items-center w-full p-1">
          {renderContent()}
        </div>
      );
    }

    // Normal olaylar için
    return (
      <div className="flex items-center w-full p-1">
        {renderContent()}
      </div>
    );
  };

  const renderStatBar = (label, homeValue, awayValue, type = 'number') => {
    const total = type === 'percentage' ? 100 : homeValue + awayValue;
    const homePercent = type === 'percentage' ? homeValue : (homeValue / total) * 100;
    const awayPercent = type === 'percentage' ? awayValue : (awayValue / total) * 100;

    return (
      <div className="stat-bar mb-4">
        <div className="stat-row flex items-center gap-4">
          <div className="stat-value w-16 text-right">{homeValue}{type === 'percentage' ? '%' : ''}</div>
          <div className="stat-bar-container flex-1 h-5 bg-gray-800 rounded overflow-hidden">
            <div className="flex h-full">
              <div 
                className="bg-blue-500 h-full" 
                style={{ width: `${homePercent}%` }} 
              />
              <div 
                className="bg-red-500 h-full" 
                style={{ width: `${awayPercent}%` }} 
              />
            </div>
          </div>
          <div className="stat-value w-16">{awayValue}{type === 'percentage' ? '%' : ''}</div>
        </div>
        <div className="stat-label text-center text-sm text-gray-300 mt-1">{label}</div>
      </div>
    );
  };

  const getPositionShort = (position) => {
    switch (position) {
      case 'G': return 'K';  // Goalkeeper -> Kaleci
      case 'D': return 'D';  // Defender -> Defans
      case 'M': return 'O';  // Midfielder -> Orta Saha
      case 'F': return 'F';  // Forward -> Forvet
      default: return position;
    }
  };

  if (loading) {
    return <div>Yükleniyor...</div>;
  }

  if (!matchDetails) {
    return <div>Maç detayları bulunamadı.</div>;
  }

  return (
    <div className="min-h-screen bg-[#0d0d0d]">
      <div style={{ width: '980px' }} className="mx-auto p-2">
        {/* Turnuva Bilgisi */}
        <div className="bg-[#1a1a1a] rounded-lg shadow-lg p-4 text-white mb-4">
          <div className="flex justify-between items-center">
            <div className="flex items-center gap-3">
              <button 
                onClick={() => navigate(-1)} 
                className="bg-[#2a2a2a] hover:bg-[#3a3a3a] p-2 rounded-lg transition-colors"
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" clipRule="evenodd" />
                </svg>
              </button>
              <h1 className="text-xl font-bold">{matchDetails.tournament.name}</h1>
              <span className="text-sm bg-[#2a2a2a] px-3 py-1 rounded-full text-gray-300">
                {matchDetails.season.name}
              </span>
            </div>
            <div className="text-right">
              <p className="text-lg font-medium">{formatDate(matchDetails.startTimestamp)}</p>
            </div>
          </div>
        </div>

        {/* Skor Tablosu */}
        <div className="bg-[#1a1a1a] rounded-lg shadow-lg p-4 mb-4 text-white">
          <div className="flex justify-between items-center px-12">
            <div className="text-center flex-1">
              <div className="bg-white p-2 rounded-lg inline-block">
                <img 
                  src={`/logos/team/${matchDetails.homeTeam.id}.png`}
                  alt={matchDetails.homeTeam.name}
                  className="w-28 h-28 object-contain"
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = '/default-team-logo.png';
                  }}
                />
              </div>
              <h2 className="font-bold text-lg mt-1">{matchDetails.homeTeam.name}</h2>
            </div>
            
            <div className="text-center mx-4 flex-1">
              <div className="text-7xl font-bold tracking-tighter">
                {matchDetails.homeScore.current} - {matchDetails.awayScore.current}
              </div>
              <div className="text-sm text-gray-400 mt-2">
                {getMatchStatus(matchDetails.status)}
              </div>
              {matchDetails.status.code === 100 && (
                <div className="text-xs text-gray-500 mt-1">
                  İlk Yarı: {matchDetails.homeScore.period1} - {matchDetails.awayScore.period1}
                </div>
              )}
            </div>
            
            <div className="text-center flex-1">
              <div className="bg-white p-2 rounded-lg inline-block">
                <img 
                  src={`/logos/team/${matchDetails.awayTeam.id}.png`}
                  alt={matchDetails.awayTeam.name}
                  className="w-28 h-28 object-contain"
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = '/default-team-logo.png';
                  }}
                />
              </div>
              <h2 className="font-bold text-lg mt-1">{matchDetails.awayTeam.name}</h2>
            </div>
          </div>

          {/* Stadyum ve Hakem Bilgileri */}
          <div className="mt-4 pt-4 border-t border-gray-800">
            <div className="grid grid-cols-3 gap-6">
              <div className="bg-[#2a2a2a] rounded-lg p-3 text-center">
                <div className="flex flex-col items-center">
                  <span className="text-xs uppercase tracking-wider text-gray-400 mb-1">Stadyum</span>
                  <p className="font-medium mb-1">{matchDetails.venue.name}</p>
                  <p className="text-sm text-gray-400">
                    Kapasite: {matchDetails.venue.stadium.capacity.toLocaleString()}
                  </p>
                </div>
              </div>
              <div className="bg-[#2a2a2a] rounded-lg p-3 text-center">
                <div className="flex flex-col items-center">
                  <span className="text-xs uppercase tracking-wider text-gray-400 mb-1">Şehir</span>
                  <p className="font-medium">{matchDetails.venue.city.name}</p>
                </div>
              </div>
              <div className="bg-[#2a2a2a] rounded-lg p-3 text-center">
                <div className="flex flex-col items-center">
                  <span className="text-xs uppercase tracking-wider text-gray-400 mb-1">Hakem</span>
                  <p className="font-medium mb-1">{matchDetails.referee.name}</p>
                  <div className="flex gap-3 text-sm text-gray-400">
                    <span>{matchDetails.referee.yellowCards} Sarı</span>
                    <span>•</span>
                    <span>{matchDetails.referee.redCards} Kırmızı</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Tab Menüsü */}
        <div className="flex justify-center mb-4">
          <div className="bg-[#1a1a1a] rounded-lg overflow-hidden">
            <button 
              className={`px-6 py-2 text-white ${selectedTab === 'events' ? 'bg-blue-600' : 'hover:bg-[#2a2a2a]'}`}
              onClick={() => setSelectedTab('events')}
            >
              Maç Detayları
            </button>
            <button 
              className={`px-6 py-2 text-white ${selectedTab === 'squad' ? 'bg-blue-600' : 'hover:bg-[#2a2a2a]'}`}
              onClick={() => setSelectedTab('squad')}
            >
              Kadro
            </button>
            <button 
              className={`px-6 py-2 text-white ${selectedTab === 'stats' ? 'bg-blue-600' : 'hover:bg-[#2a2a2a]'}`}
              onClick={() => setSelectedTab('stats')}
            >
              İstatistikler
            </button>
          </div>
        </div>

        {/* Tab İçerikleri */}
        <div className="bg-[#1a1a1a] rounded-lg shadow-lg p-4 text-white">
          {selectedTab === 'events' && (
            <div>
              <div className="flex items-center gap-4 mb-4">
                <h2 className="text-xl font-bold">Maç Olayları</h2>
                <div className="flex-1 border-b border-gray-800"></div>
              </div>
              
              <div className="space-y-1">
                {incidents && renderIncidents(incidents)}
              </div>
            </div>
          )}

          {selectedTab === 'squad' && (
            <div>
              <div className="flex items-center gap-4 mb-6">
                <h2 className="text-xl font-bold">Kadrolar</h2>
                <div className="flex-1 border-b border-gray-800"></div>
              </div>

              <div className="grid grid-cols-2 gap-8">
                {/* Ev Sahibi Takım */}
                <div>
                  <div className="flex items-center gap-3 mb-4">
                    <img 
                      src={`/logos/team/${matchDetails.homeTeam.id}.png`}
                      alt={matchDetails.homeTeam.name}
                      className="w-8 h-8 object-contain"
                    />
                    <h3 className="font-bold text-lg">{matchDetails.homeTeam.name}</h3>
                  </div>

                  <div className="space-y-6">
                    {/* İlk 11 */}
                    <div>
                      <div className="flex items-center justify-between mb-2">
                        <h4 className="text-sm font-medium text-gray-400">İLK 11</h4>
                        <span className="text-sm text-gray-400">Diziliş: {home.formation}</span>
                      </div>
                      <div className="space-y-2">
                        {home.players
                          .filter(p => !p.substitute)
                          .map((player, index) => (
                            <div key={index} className="flex items-center gap-3 bg-[#2a2a2a] rounded-lg p-2">
                              <div className="min-w-[28px] h-[28px] flex items-center justify-center bg-[#3a3a3a] rounded-full text-sm font-bold">
                                {player.shirtNumber}
                              </div>
                              <div className="flex-1">
                                <div className="font-medium">{player.player.name}</div>
                                <div className="text-xs text-gray-400 flex items-center gap-2">
                                  {player.statistics?.rating && (
                                    <span className="flex items-center gap-1">
                                      <span className="flex items-center">
                                        {[...Array(5)].map((_, i) => {
                                          const rating = (player.statistics.rating / 10) * 5;
                                          const isHalf = rating - i > 0 && rating - i < 1;
                                          const isFull = rating - i >= 1;
                                          
                                          return (
                                            <span key={i} className="text-2xl text-yellow-400">
                                              <i className={isFull ? 'fas fa-star' : isHalf ? 'fas fa-star-half-alt' : 'far fa-star'}></i>
                                            </span>
                                          );
                                        })}
                                      </span>
                                      <span className="text-gray-400 text-base ml-1">
                                        ({(player.statistics.rating / 2).toFixed(1)} Puan)
                                      </span>
                                    </span>
                                  )}
                                  {getPositionShort(player.position)}
                                  {player.captain && <span className="text-yellow-400">(K)</span>}
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>

                    {/* Yedekler */}
                    <div>
                      <h4 className="text-sm font-medium text-gray-400 mb-2">YEDEKLER</h4>
                      <div className="space-y-2">
                        {home.players
                          .filter(p => p.substitute)
                          .map((player, index) => (
                            <div key={index} className="flex items-center gap-3 bg-[#2a2a2a] rounded-lg p-2">
                              <div className="min-w-[28px] h-[28px] flex items-center justify-center bg-[#3a3a3a] rounded-full text-sm font-bold">
                                {player.shirtNumber}
                              </div>
                              <div className="flex-1">
                                <div className="font-medium">{player.player.name}</div>
                                <div className="text-xs text-gray-400 flex items-center gap-2">
                                  {player.statistics?.rating && (
                                    <span className="flex items-center gap-1">
                                      <span className="flex items-center">
                                        {[...Array(5)].map((_, i) => {
                                          const rating = (player.statistics.rating / 10) * 5;
                                          const isHalf = rating - i > 0 && rating - i < 1;
                                          const isFull = rating - i >= 1;
                                          
                                          return (
                                            <span key={i} className="text-2xl text-yellow-400">
                                              <i className={isFull ? 'fas fa-star' : isHalf ? 'fas fa-star-half-alt' : 'far fa-star'}></i>
                                            </span>
                                          );
                                        })}
                                      </span>
                                      <span className="text-gray-400 text-base ml-1">
                                        ({(player.statistics.rating / 2).toFixed(1)} Puan)
                                      </span>
                                    </span>
                                  )}
                                  {getPositionShort(player.position)}
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>

                {/* Deplasman Takımı */}
                <div>
                  <div className="flex items-center gap-3 mb-4">
                    <img 
                      src={`/logos/team/${matchDetails.awayTeam.id}.png`}
                      alt={matchDetails.awayTeam.name}
                      className="w-8 h-8 object-contain"
                    />
                    <h3 className="font-bold text-lg">{matchDetails.awayTeam.name}</h3>
                  </div>

                  <div className="space-y-6">
                    {/* İlk 11 */}
                    <div>
                      <div className="flex items-center justify-between mb-2">
                        <h4 className="text-sm font-medium text-gray-400">İLK 11</h4>
                        <span className="text-sm text-gray-400">Diziliş: {away.formation}</span>
                      </div>
                      <div className="space-y-2">
                        {away.players
                          .filter(p => !p.substitute)
                          .map((player, index) => (
                            <div key={index} className="flex items-center gap-3 bg-[#2a2a2a] rounded-lg p-2">
                              <div className="min-w-[28px] h-[28px] flex items-center justify-center bg-[#3a3a3a] rounded-full text-sm font-bold">
                                {player.shirtNumber}
                              </div>
                              <div className="flex-1">
                                <div className="font-medium">{player.player.name}</div>
                                <div className="text-xs text-gray-400 flex items-center gap-2">
                                  {player.statistics?.rating && (
                                    <span className="flex items-center gap-1">
                                      <span className="flex items-center">
                                        {[...Array(5)].map((_, i) => {
                                          const rating = (player.statistics.rating / 10) * 5;
                                          const isHalf = rating - i > 0 && rating - i < 1;
                                          const isFull = rating - i >= 1;
                                          
                                          return (
                                            <span key={i} className="text-2xl text-yellow-400">
                                              <i className={isFull ? 'fas fa-star' : isHalf ? 'fas fa-star-half-alt' : 'far fa-star'}></i>
                                            </span>
                                          );
                                        })}
                                      </span>
                                      <span className="text-gray-400 text-base ml-1">
                                        ({(player.statistics.rating / 2).toFixed(1)} Puan)
                                      </span>
                                    </span>
                                  )}
                                  {getPositionShort(player.position)}
                                  {player.captain && <span className="text-yellow-400">(K)</span>}
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>

                    {/* Yedekler */}
                    <div>
                      <h4 className="text-sm font-medium text-gray-400 mb-2">YEDEKLER</h4>
                      <div className="space-y-2">
                        {away.players
                          .filter(p => p.substitute)
                          .map((player, index) => (
                            <div key={index} className="flex items-center gap-3 bg-[#2a2a2a] rounded-lg p-2">
                              <div className="min-w-[28px] h-[28px] flex items-center justify-center bg-[#3a3a3a] rounded-full text-sm font-bold">
                                {player.shirtNumber}
                              </div>
                              <div className="flex-1">
                                <div className="font-medium">{player.player.name}</div>
                                <div className="text-xs text-gray-400 flex items-center gap-2">
                                  {player.statistics?.rating && (
                                    <span className="flex items-center gap-1">
                                      <span className="flex items-center">
                                        {[...Array(5)].map((_, i) => {
                                          const rating = (player.statistics.rating / 10) * 5;
                                          const isHalf = rating - i > 0 && rating - i < 1;
                                          const isFull = rating - i >= 1;
                                          
                                          return (
                                            <span key={i} className="text-2xl text-yellow-400">
                                              <i className={isFull ? 'fas fa-star' : isHalf ? 'fas fa-star-half-alt' : 'far fa-star'}></i>
                                            </span>
                                          );
                                        })}
                                      </span>
                                      <span className="text-gray-400 text-base ml-1">
                                        ({(player.statistics.rating / 2).toFixed(1)} Puan)
                                      </span>
                                    </span>
                                  )}
                                  {getPositionShort(player.position)}
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {selectedTab === 'stats' && (
            <div>
              <h2 className="text-xl font-bold mb-4">Maç İstatistikleri</h2>
              {renderStatBar('Top Hakimiyeti', 41, 59, 'percentage')}
              {renderStatBar('Toplam Şut', 14, 6)}
              {renderStatBar('İsabetli Şut', 4, 2)}
              {renderStatBar('Korner', 6, 8)}
              {renderStatBar('Faul', 17, 12)}
              {renderStatBar('Pas', 338, 474)}
              {renderStatBar('İsabetli Pas', 260, 397)}
              {renderStatBar('Ofsayt', 3, 1)}
              {renderStatBar('Sarı Kart', 5, 3)}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MatchDetail; 